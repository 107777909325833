<template>
  <label :class="['ui-checkbox', { error }]">
    <input v-model="model" :name="name" :value="inputValue" class="inp-cbx" type="checkbox" />
    <span>
      <svg width="12px" height="10px" viewbox="0 0 12 10">
        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
      </svg>
    </span>
    <slot></slot>
  </label>
</template>

<script>
export default {
  name: "UiCheckbox",

  props: {
    value: {
      type: [Boolean, Object, String, Array]
    },
    inputValue: String,
    name: {
      type: String,
      default: 'name',
    },
    error: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
     model: {
       get() {
         return this.value;
       },
       set(value) {
         this.$emit("input", value);
       },
     }
  }
}
</script>

<style lang="scss" scoped>
.ui-checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;

  &.error {

    span {
      border: 1px solid #E21F1F;
    }

    p {
      color: #E21F1F;
    }
  }

  input {
    display: none;
  }

  span {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 3px;
    transform: scale(1) translate3d(0, 0, 0);
    border: 1px solid #9098A9;
    transition: all 0.2s ease;

    svg {
      position: absolute;
      top: 3px;
      left: 2px;
      fill: none;
      stroke: #FFFFFF;
      stroke-width: 2;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-dasharray: 16px;
      stroke-dashoffset: 16px;
      transition: all 0.3s ease;
      transition-delay: 0.1s;
      transform: translate3d(0, 0, 0);
    }

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      background: #E21F1F;
      display: block;
      transform: scale(0);
      opacity: 1;
      border-radius: 50%;
    }
  }

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    color: #343432;
    transition: 0.3s;
  }

  a {
    color: #E21F1F;
    text-decoration: none;
  }
}


.cbx span {
  display: inline-block;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}
.cbx span {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  transform: scale(1);
  vertical-align: middle;
  border: 1px solid #9098A9;
  transition: all 0.2s ease;
}
.cbx span svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #FFFFFF;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}
.cbx span:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #E21F1F;
  display: block;
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
}
.cbx:hover span {
  border-color: #E21F1F;
}

.inp-cbx:checked + span {
  background: #E21F1F;
  border-color: #E21F1F;
  animation: wave-46 0.4s ease;
}
.inp-cbx:checked + span svg {
  stroke-dashoffset: 0;
}
.inp-cbx:checked +  span:before {
  transform: scale(3.5);
  opacity: 0;
  transition: all 0.6s ease;
}

@keyframes wave-46 {
  50% {
    transform: scale(0.9);
  }
}

</style>
