<template>
  <div class="taxes-coefficients">
    <div class="taxes-coefficients__select">
      <p>{{ $t('incident-type') }}</p>
      <ui-select @input="changeSelectValue" :options="getTypes" v-model="incidentType" />
    </div>
    <Loader v-model="$store.state.adminStore.userVariablesLoader" />
    <UiNotification v-model="showNotification" :message="message" />
    <template v-if="!$store.state.adminStore.userVariablesLoader && incidentType.name">
      <Table
          v-for="(item, key) in getSpecsTypes"
          :key="key"
          :data="item.values"
          :descriptionList="[{title: $t('title'), width: 440}]"
          :title="item.name"
          :item="item"
          :actionText="$t('new-row')"
          ref="table"
          @updateTitle="updateTitle($event)"
          @addRowField="addRowFieldValues($event)"
          @deleteRowField="deleteRowFieldValues($event)"
          @changeRowField="changeRowFieldValues($event)"
          @addColumn="addColumn($event)"
          @deleteColumn="deleteColumn($event)"
          @editColumn="editColumn($event)"
          @addNewNumber="addNewNumber($event)"
          @editColumnNumber="editColumnNumber($event)"
          is-delete
          addColumns
          :columnsCount="item.columns?.length"
          :columns="item.columns"
          newTable
          :isGlobeIcon="true"
      />
    </template>
<!--    <Table-->
<!--        v-if="newTable && !$store.state.adminStore.userVariablesLoader"-->
<!--        :descriptionList="[{title: 'Название', width: 440}]"-->
<!--        :actionText="'Новая строка'"-->
<!--        @addTitle="addTitle($event)"-->
<!--        hideEditIcon-->
<!--        res="table"-->
<!--    />-->
    <add-new-table v-if="newTable && incidentType.name && !$store.state.adminStore.userVariablesLoader" :title="$t('creating-new-table')">
      <template #body>
        <div class="main">
          <div class="column">
            <div class="row">
              <p>{{ $t('select-the-number-of-columns') }}</p>
              <ui-select v-model="countColumn" :options="optionsColumn"/>
            </div>
            <div class="row">
              <ui-input :placeholder="$t('table-name')" v-model="addTableTitle"/>
              <ui-checkbox v-model="regionVal">
                <p>{{ $t('region') }}</p>
              </ui-checkbox>
            </div>
            <div class="column">
              <div class="row" v-for="(item, key) in optionsColumn.slice(0, countColumn.name)" :key="key">
                <ui-input :placeholder="$t('column-name')" v-model="item.value"/>
              </div>
            </div>
            <div class="row">
              <ui-button @click="addTable" :disabled="addTableTitle === ''">{{ $t('create') }}</ui-button>
              <p @click="cancelAddTable" style="cursor: pointer">{{ $t('cancel-2') }}</p>
            </div>
          </div>
        </div>
      </template>
    </add-new-table>
    <div class="user-informer__action">
      <ui-button v-if="incidentType.name && !$store.state.adminStore.userVariablesLoader" @click="addNewTable" color="error">
        <img src="@/assets/svg/icon-plus-white.svg" alt="">
        {{ $t('new-table') }}
      </ui-button>
    </div>
  </div>
</template>

<script>
import UiSelect from "@/components/ui/UiSelect.vue";
import Loader from "@/components/ui/UiLoader.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import UiButton from "@/components/ui/UiButton.vue";
import UiNotification from "@/components/ui/UiNotification.vue";
import AddNewTable from "@/components/common/admin/AddNewTable.vue";
import UiInput from "@/components/ui/UiInput.vue";
import UiCheckbox from "@/components/ui/UiCheckbox.vue";

export default {
  name: "TaxesCoefficients",
  components: {
    UiCheckbox,
    UiInput,
    AddNewTable,
    UiNotification,
    UiButton,
    Loader,
    UiSelect,
    Table: () => import('@/components/ui/UiTable'),
  },

  data() {
    return {
      incidentType: { name: '', value: '' },
      newTable: false,
      showNotification: false,
      message: '',
      countColumn: {name: ''},
      optionsColumn: [
        {
          name: 1,
          value: ''
        },
        {
          name: 2,
          value: ''
        },
        {
          name: 3,
          value: ''
        }
      ],
      addTableTitle: '',
      regionVal: false
    }
  },

  methods: {
    ...mapActions([
      'columns',
      'addColumns',
      'deleteColumns',
      'editColumns',
      'addColumnNumber',
      'specsTypes',
      'addVariable',
      'editVariable',
      'addVariableValues',
      'deleteVariableValues',
      'editVariableValues',
      'columns'
    ]),
    ...mapMutations(['setUserVariablesLoader']),

    addTable() {
      this.setUserVariablesLoader(true)
      this.addVariable({
        name: this.addTableTitle,
        orderTypeId: +this.incidentType.id,
        dataType: "Key",
        specGroup: "Dictionary",
        valuesClass: this.regionVal ? 'Region' : 'General'
      }).then((res) => {
        this.optionsColumn.forEach((el) => {
          if (el.value !== '') {
            const data = {
              specTypeId: res.id,
              name: el.value
            }
            this.addColumns(data)
          }
            this.getSpecTypes(this.$t('table-successfully-added'))
            this.newTable = false;
            el.value = ''
            this.countColumn = {name: ''}
            this.addTableTitle = ''
        })
        this.regionVal = false
      })
    },

    cancelAddTable () {
      this.newTable = !this.newTable
      this.regionVal = false
    },

    changeSelectValue() {
      this.setUserVariablesLoader(true)
      let payload = {
        orderTypeId: this.incidentType.id,
        specGroup: 'Dictionary'
      };
      this.specsTypes(payload).then(() => {
        this.getSpecsTypes.forEach((el) => {
          this.columns({specTypeId: el.id}).then((res) => this.getSpecsTypes.forEach(val => {
                if (val.id === res[0]?.specTypeId) {
                  val.columns = res
                }
              })
          )
        })
        setTimeout(() => {
          this.setUserVariablesLoader(false)
        }, 500);
      })
    },

    getSpecTypes(message) {
      let payload = {
        orderTypeId: this.incidentType.id,
        specGroup: 'Dictionary'
      };
      this.specsTypes(payload).then(() => {
        this.getSpecsTypes.forEach(el =>
            this.columns({specTypeId: el.id}).then((res) => this.getSpecsTypes.forEach(val => {
                  if (val.id === res[0]?.specTypeId) {
                    val.columns = res
                  }
                })
            ))
        setTimeout(() => {
          this.setUserVariablesLoader(false)
          this.showNotification = true
          this.message = message
        }, 500);
      })
    },

    addNewTable () {
      if(this.incidentType) {
        this.newTable = true
      }
    },

    addTitle(name) {
      this.setUserVariablesLoader(true)
      this.addVariable({
        name: name,
        orderTypeId: +this.incidentType.id,
        dataType: "Key",
        specGroup: "Dictionary"
      }).then(() => {
        this.getSpecTypes(this.$t('table-successfully-added'))
        this.newTable = false;
        this.setUserVariablesLoader(false)
      })
    },

    updateTitle(data) {
      this.setUserVariablesLoader(true)
      this.editVariable({
        name: data.value,
        id: +data.id,
        dataType: 'Key',
        orderTypeId: +this.incidentType.id,
        specGroup: "Dictionary"
      })
        .then(() => {
          this.getSpecTypes(this.$t('table-successfully-update'))
        })
    },

    addRowFieldValues (data) {
      this.setUserVariablesLoader(true)

      let payload = {
        id: data.typeId,
        name: data.name,
      };

      this.addVariableValues(payload).then(() => {
        this.getSpecTypes(data?.valuesClass === 'Region' ? this.$t('region-successfully-added') : this.$t('name-successfully-added'))
      })
    },

    deleteRowFieldValues (data) {
      this.setUserVariablesLoader(true)

      let payload = {
        id: this.incidentType.id,
        value: data.id,
        name: data.name
      };

      this.deleteVariableValues(payload).then(() => {
        this.getSpecTypes(data?.valuesClass === 'Region' ? this.$t('region-successfully-deleted') : this.$t('name-successfully-deleted'))
      })
    },

    changeRowFieldValues(data) {
      this.setUserVariablesLoader(true)

      let payload = {
        id: this.incidentType.id,
        value: data.id,
        name: data.name,
        nameIntEn: data.nameIntEn
      };

      this.editVariableValues(payload).then(() => {
        this.getSpecTypes(data?.valuesClass === 'Region' ? this.$t('region-successfully-update') : this.$t('name-successfully-updated'))
      })
    },

    addColumn(info) {
      const data = {
        specTypeId: info.specTypeId,
        name: info.name
      }
      this.setUserVariablesLoader(true)
      this.addColumns(data).then(() => {
        this.getSpecTypes(this.$t('column-successfully-added'))
        this.columns({specTypeId: info.specTypeId})
      })
    },

    deleteColumn(item) {
      this.setUserVariablesLoader(true)
      this.deleteColumns({id: item.id}).then(() => {
        this.getSpecTypes(this.$t('column-successfully-deleted'))
        this.columns({specTypeId: item.specTypeId})
      })
    },

    editColumn(item) {
      this.setUserVariablesLoader(true)
      this.editColumns({id: item.id, name: item.editForm}).then(() => {
        this.getSpecTypes(this.$t('column-successfully-updated'))
      })
    },

    addNewNumber(data) {
      this.setUserVariablesLoader(true)
      this.addColumnNumber({id: data.columnId, specValueId: data.rowId, amount: +data.number?.numVal || +data.amount}).then(() => {
        this.getSpecTypes(this.$t('column-successfully-updated'))
      })
    },

    editColumnNumber(data) {
      this.setUserVariablesLoader(true)
      this.addColumnNumber({id: data.column.id, specValueId: data.row.id, amount: +data.amount || +data.number?.numVal}).then(() => {
        this.getSpecTypes(this.$t('column-successfully-updated'))
      })
    },
  },

  computed: {
    ...mapGetters(['getColumns', 'getTypes', 'getSpecsTypes']),
  }
}
</script>

<style lang="scss" scoped>
.taxes-coefficients {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  overflow-x: auto;
  min-height: 500px;

  button {
    max-width: 366px;
  }

  &__select {
    display: flex;
    align-items: center;
    gap: 20px;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
    border-radius: 20px;
    max-width: 490px;
    width: 100%;
    padding: 20px;

    p {
      font-size: 16px;
      line-height: 19px;
      color: #1B1A1F;
      white-space: nowrap;
    }
  }
}
</style>