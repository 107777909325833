<template>
  <div class="add-table">
    <div class="add-table__title">
      <h2>{{ title }}</h2>
    </div>
    <slot name="body"></slot>
  </div>
</template>

<script>

export default {
  name: "AddNewColumnsTable",
  props: {
    addNewRow: {
      type: Boolean,
      default: false
    },
    addNewTable: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.add-table {
  max-width: 1000px;
  width: 100%;
  border-radius: 20px;
  background: #FFFFFF;
  box-shadow: 2px 2px 10px 0 rgba(153, 153, 153, 0.20);
  &__title {
    padding: 10px 20px;
    width: 100%;
    background: #E21F1F;
    border-radius: 20px 20px 0 0;
    height: 37px;

    h2 {
      color: #FFFFFF;
      font-size: 14px;
      font-weight: 400;
    }
  }
}
.row {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}
.column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.main {
  padding: 36px 38px 30px 26px;
}
</style>